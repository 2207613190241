import { faPhoneAlt, faMapMarkedAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql } from "gatsby"
import * as React from "react"
import { Helmet } from "react-helmet"
import Breadcrumb from "../components/common/breadcrumb/breadcrumb"
import ContactForm from "../components/common/contact-form/contact-form"
import Layout from "../components/layout/Layout"

const ContactanosPage = () => {
    return (
        <Layout>
            <div className={`contact-page-wrap`}>
                <Helmet>
                    <title>Mahpsa | Contáctanos</title>
                </Helmet>
                <Breadcrumb title={'Contáctanos'} />
                <div className="section-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <div className="box-icon">
                                    <div className="icon">
                                        {/* <i className="opal-icon-phone"></i> */}
                                        <FontAwesomeIcon icon={faPhoneAlt} size='1x' className="text-primary" />
                                    </div>
                                    <div className="content">
                                        <span className="title text-uppercase mb-1">Telefonos :</span>
                                            <p className="description">273 0398</p>
                                            <p className="description">273 0409</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <div className="box-icon">
                                    <div className="icon">
                                        {/* <i className="opal-icon-point"></i> */}
                                        <FontAwesomeIcon icon={faMapMarkedAlt} size='1x' className="text-primary" />
                                    </div>
                                    <div className="content">
                                        <span className="title text-uppercase">direccion :</span>
                                        <p className="description">Tomas Marsano Ave 2875 Of. 607</p>
                                        <p className="description">Surco, Lima</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <div className="box-icon no-border">
                                    <div className="icon">
                                        {/* <i className="opal-icon-envelope"></i> */}
                                        <FontAwesomeIcon icon={faEnvelope} size='1x' className="text-primary" />
                                    </div>
                                    <div className="content">
                                        <span className="title text-uppercase">E-MAIL :</span>
                                            <p className="description">nformes@mahpsa.com.pe</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ContactanosPage